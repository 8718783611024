import React, { Component } from "react";
// import VisibilitySensor from "react-visibility-sensor";

export class Video extends Component {
    constructor(props) {
        super(props);

        this.checkScroll = this.checkScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.checkScroll, false);
    }

    checkScroll() {
        const video = document.getElementById("vid" + this.props.video);

        let y = video.getBoundingClientRect().top + window.scrollY;

        let visible = false;
        // console.log(y + window.innerHeight * 0.2, window.pageYOffset);

        visible =
            y + window.innerHeight * 0.2 > window.pageYOffset &&
            y - window.innerHeight * 0.8 < window.pageYOffset;
        // console.log(visible);

        if (visible === true) {
            video.play();
            this.props.setColor(this.props.color2, this.props.color);
        } else {
            video.pause();
        }
    }

    render() {
        // const video = document.getElementById("vid" + this.props.video);
        // video.muted = this.props.muted;

        const sectionStyle = {
            backgroundColor: this.props.color
        };
        return (
            <div>
                <section style={sectionStyle}>
                    <video
                        id={"vid" + this.props.video}
                        width="100%"
                        height="100%"
                        autoPlay={this.props.first === true ? true : false}
                        playsInline
                        loop
                        preload={this.props.first === true ? "auto" : "none"}
                        // controls
                        muted={this.props.muted}
                        poster={this.props.poster}
                    >
                        <source src={this.props.video} type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </section>
            </div>
        );
    }
}

export default Video;
