import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export class Header extends Component {
    render() {
        return (
            <header className="header">
                <NavLink
                    to="/"
                    className="logo"
                    exact
                    activeClassName="active"
                    style={{ color: this.props.color }}
                >
                    IMPROVISUAL
                </NavLink>
                <NavLink
                    to="/info/"
                    activeClassName="active"
                    style={{ color: this.props.color }}
                >
                    INFO
                </NavLink>
            </header>
        );
    }
}

export default Header;
