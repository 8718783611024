import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedRoute } from "react-router-transition";

import "./App.css";
import Header from "./components/Header";
import Index from "./components/Index";
import Info from "./components/Info";

export class App extends Component {
    constructor(props) {
        super(props);

        this.setColor = this.setColor.bind(this);
        this.switchLang = this.switchLang.bind(this);

        this.state = {
            color: "#411719",
            bgColor: "#95696B",
            lang: "nl"
        };
    }

    setColor(color, bgColor) {
        this.setState({ color, bgColor });
    }

    switchLang() {
        console.log("switch lang");

        this.setState({ lang: this.state.lang === "nl" ? "en" : "nl" });
    }

    render() {
        return (
            <Router>
                <div className="App">
                    <Header color={this.state.color} />

                    <AnimatedRoute
                        className="info-route"
                        atEnter={{ offset: 100 }}
                        atLeave={{ offset: 100 }}
                        atActive={{ offset: 50 }}
                        mapStyles={styles => ({
                            // transform: `translateX(${styles.offset}%)`,
                            left: `${styles.offset}%`,
                            zIndex: 1000,
                            position: "fixed"
                            // left: "50%"
                        })}
                        path="/info/"
                        exact
                        color={this.state.color}
                        bgColor={this.state.bgColor}
                        // wrapperComponent={false}
                        component={Info}
                        render={routeProps => (
                            <Info
                                color={routeProps.color}
                                bgColor="#ff0000"
                                switchLang={this.switchLang}
                                lang={this.state.lang}
                            />
                        )}
                    />

                    <Index setColor={this.setColor} color={this.state.color} />
                </div>
            </Router>
        );
    }
}

export default App;
