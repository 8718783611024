import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

function Info(props) {
    console.log("infoclr", props.bgColor);

    return (
        <div
            className="info"
            style={{
                backgroundColor: props.color,
                color: props.bgColor
            }}
        >
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 info__header">
                        <div className="info__lang">
                            <button
                                className={props.lang === "nl" && "active"}
                                onClick={props.switchLang}
                            >
                                NL
                            </button>
                            /
                            <button
                                className={props.lang === "en" && "active"}
                                onClick={props.switchLang}
                            >
                                EN
                            </button>
                        </div>
                        <Link to="/" className="info__close">
                            ✕
                        </Link>
                    </div>
                    <div className="col-sm-12">
                        {props.lang === "nl" ? (
                            <div>
                                <p>
                                    IMPROVISUALS zijn live visualisaties van
                                    geïmproviseerde muziek.
                                </p>
                                <p>
                                    In beeld en geluid verkent Martijn van
                                    Bachum de spanning tussen op regels
                                    gebaseerde systemen, patronen en structuren
                                    enerzijds en intuïtie en improvisatie
                                    anderzijds.
                                </p>
                                <p>
                                    Tijdens een live performance wordt iedere
                                    noot gevisualiseerd op een projectie. Zo
                                    ontstaat er een datavisualisatie tijdens het
                                    muziekstuk. Hierdoor kun je de muziek zowel
                                    in het nu beleven als teruglezen wat er is
                                    gebeurd. Vormen zoals stippen en lijnen
                                    tonen het verloop van nuances als toon en
                                    volume met precisie.
                                </p>
                                <p>
                                    De geïmproviseerde muziek is een conversatie
                                    met het scherm. Wat wordt gespeeld bepaald
                                    wat op het scherm gebeurt, maar de visuals
                                    beïnvloeden het spel ook.
                                </p>
                            </div>
                        ) : (
                            <div>
                                <p>
                                    IMPROVISUALS are live visualizations of
                                    improvised music.
                                </p>
                                <p>
                                    In both sound and vision, Martijn van Bachum
                                    explores the tension between rule-based
                                    systems, patterns and structures on the one
                                    hand and intuition and improvisation on the
                                    other hand.
                                </p>
                                <p>
                                    During a live performance, every note is
                                    being visualized on a projection. A data
                                    visualization is being built up during a
                                    music piece, which means that you can
                                    experience the music in the now, but also
                                    read back what happened. Shapes like dots
                                    and lines show nuances like pitch and volume
                                    over time precisely.
                                </p>
                                <p>
                                    Playing music is a conversation with the
                                    screen. My playing determines what happens
                                    on the screen, but the visuals influences
                                    what I play too.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <p>
                            Follow me
                            <br />
                            <a
                                style={{ color: props.bgColor }}
                                href="https://www.instagram.com/impro_visual"
                            >
                                Instagram
                            </a>
                        </p>
                    </div>
                    <div className="col-sm-12">
                        <p>
                            Code
                            <br />
                            <a
                                style={{ color: props.bgColor }}
                                href="https://github.com/martijnbac/improvisual"
                            >
                                Github
                            </a>
                        </p>
                    </div>
                    <div className="col-sm-12">
                        <p>
                            Contact / booking
                            <br />
                            <a
                                style={{ color: props.bgColor }}
                                href="https://martijnvanbachum.com"
                            >
                                martijnvanbachum.com
                            </a>
                            <br />
                            <a
                                style={{ color: props.bgColor }}
                                href="mailto:info@martijnvanbachum.com"
                            >
                                info@martijnvanbachum.com
                            </a>
                        </p>
                    </div>
                </div>
                <div className="row"></div>
            </div>
        </div>
    );
}

export default Info;
