import React, { Component } from "react";
import Video from "../components/Video";

export class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            muted: true
        };

        this.toggleMute = this.toggleMute.bind(this);
    }

    toggleMute(e) {
        e.preventDefault();
        this.setState({ muted: !this.state.muted });
    }

    render() {
        return (
            <div>
                <button
                    className="mute"
                    onClick={this.toggleMute}
                    style={{ color: this.props.color }}
                >
                    SOUND {this.state.muted ? "✕" : "♫"}
                </button>
                <Video
                    poster="poster/30.png"
                    video="video/30.mp4"
                    color="#95696B"
                    color2="#411719"
                    first={true}
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />

                <Video
                    poster="poster/29.png"
                    video="video/29.mp4"
                    color="#8ACBC1"
                    color2="#2F5A54"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />

                <Video
                    poster="poster/28.png"
                    video="video/28.mp4"
                    color="#cfb078"
                    color2="#013046"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/27.png"
                    video="video/27.mp4"
                    color="#76B7AD"
                    color2="#383450"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/26.png"
                    video="video/26.mp4"
                    color="#D7CDE0"
                    color2="#375752"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/25.png"
                    video="video/25.mp4"
                    color="#8A93F8"
                    color2="#3C48E4"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/24.png"
                    video="video/24.mp4"
                    color="#900100"
                    color2="#C61418"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/23.png"
                    video="video/23.mp4"
                    color="#F71D20"
                    color2="#C61418"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/22.png"
                    video="video/22.mp4"
                    color="#900100"
                    color2="#C61418"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/21.png"
                    video="video/21.mp4"
                    color="#cccccc"
                    color2="#03333A"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/20.png"
                    video="video/20.mp4"
                    color="#aaaaaa"
                    color2="#03333A"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/18.png"
                    video="video/18.mp4"
                    color="#B095B5"
                    color2="#684C4B"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/17.png"
                    video="video/17.mp4"
                    color="#B9A8BD"
                    color2="#000000"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/16.png"
                    video="video/16.mp4"
                    color="#cccccc"
                    color2="#000000"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/15.png"
                    video="video/15.mp4"
                    color="#eeeeee"
                    color2="#000000"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/14.png"
                    video="video/14.mp4"
                    color="#677474"
                    color2="#CAC9CA"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/13.png"
                    video="video/13.mp4"
                    color="#97A4A2"
                    color2="#4D2D27"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/12.png"
                    video="video/12.mp4"
                    color="#8CD0D5"
                    color2="#000000"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/11.png"
                    video="video/11.mp4"
                    color="#cccccc"
                    color2="#0000000"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/10.png"
                    video="video/10.mp4"
                    color="#1DB899"
                    color2="#000000"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/9.png"
                    video="video/9.mp4"
                    color="#cccccc"
                    color2="#1DB899"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/8.png"
                    video="video/8.mp4"
                    color="#666666"
                    color2="#ffffff"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/7.png"
                    video="video/7.mp4"
                    color="#aaaaaa"
                    color2="#000000"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/6.png"
                    video="video/6.mp4"
                    color="#cccccc"
                    color2="#043434"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/5.png"
                    video="video/5.mp4"
                    color="#aaaaaa"
                    color2="#0C2BBB"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/4.png"
                    video="video/4.mp4"
                    color="#cccccc"
                    color2="#000000"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/3.png"
                    video="video/3.mp4"
                    color="#aaaaaa"
                    color2="#082BB8"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
                <Video
                    poster="poster/2.png"
                    video="video/2.mp4"
                    color="#E6D0B1"
                    color2="#285E56"
                    muted={this.state.muted}
                    setColor={this.props.setColor}
                />
            </div>
        );
    }
}

export default Index;
